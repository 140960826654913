import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import VPlayer from "vnetwork-player";
import "vnetwork-player/dist/vnetwork-player.min.css";
import Hls from 'hls.js'


class TabContent extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {
          openModel: null
        };
    }

    resourcesRestritForSuppliers = [11];

    isSupplier () {
        let { userId } = this.props;
        return `${userId}`.toLowerCase().includes('okta_');
    }
    
    openModal(res) {
        this.setState({
            openModel: res
        });
    }
    
    closeModal(self) {
        try {
            self.setState({
                openModel: null
            });
        } catch (e) {
            console.log(e);
        }
        
    }

    lastVisited = (lastClicked) => {
        localStorage.setItem('lastClicked', lastClicked);
    }

    render() {
        let {collectionName, collectionHtml, resources} = this.props;

        let lastClicked = localStorage.getItem('lastClicked');

        return <div className='tab-section'>
            <div className='tab-header'>{collectionName}</div>
            <div className='tab-content'>{ReactHtmlParser(collectionHtml)}</div>
            <div className='tab-resources'>{
                resources.map(res => {
                    const {resId, resType, resLink, resCaption} = res;

                    let resKey = `res-${resId}`;

                    let restrict = this.isSupplier() && this.resourcesRestritForSuppliers.includes(resId);

                    if (resType === 'link') {

                        return <div className='resource-item-wrapper'><span id={resKey} onMouseDown={
                            () => this.lastVisited(resKey)
                        } onTouchStart={this.lastVisited} className={`resource-item link${lastClicked === resKey ? ' last' : ''}`} onClick={
                            () => this.lastVisited(resKey)
                        }><span className='ico'>⤻</span> <a href={resLink}>{resCaption}</a></span></div>

                    } else if (resType === 'video') {

                        return <div className='resource-item-wrapper'>
                            <span id={resKey} onMouseDown={
                                () => this.lastVisited(resKey)
                            } onTouchStart={
                                () => this.lastVisited(resKey)
                            } onClick={
                                () => {
                                    if (!restrict) {
                                        this.openModal(resKey)
                                    }
                                }
                            } className={`resource-item video${lastClicked === resKey ? ' last' : ''}${restrict ? ' disable' : ''}`}><span className='ico'>▶</span> {resCaption}</span>

                            { this.state.openModel === resKey && <div className={`video-wrapper ${this.state.openModel === resKey ? ' display': ''}`}>
                                <div className='video-header'>
                                    <div className='video-caption'>{resCaption}</div>
                                    <div onClick={() => { this.closeModal(this) }} className='video-close'>Close X</div>
                                </div>
                                <div className='video-content'>
                                    <div className='video-content-inner'>
                                    {
                                        resLink && resLink.length > 0 ? <VPlayer
                                        source={resLink}
                                        color="#ff0000"
                                        Hls={Hls}
                                        /> : <span>No video link here.</span>
                                    }
                                    </div>
                                </div>
                            </div> }
                        </div>

                    }

                    return <span></span>
                })
            }</div>
        </div>
    }
    
    
}

export default TabContent;