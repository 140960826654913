import { takeLatest } from 'redux-saga/effects';
import { USER, CONTENT, TOKEN } from 'actions/actionTypes';
import { loadUserAsync, loadContentAsync, getTokenAsync } from './UserSaga';

const userSaga = [
        takeLatest(USER.REQUEST, loadUserAsync),
        takeLatest(CONTENT.REQUEST, loadContentAsync),
        takeLatest(TOKEN.REQUEST, getTokenAsync)
];

export default userSaga;
