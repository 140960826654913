import React from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import TabContent from './TabContent';

const selectContent = state => state.content;
const { TabPane: Pane } = Tabs;

function PageContent({userId}) {

    const content = useSelector(selectContent);

    const { content: {page, collections} } = content;

    if (!(page && collections)) {
        return <span></span>;
    }

    let tabPanes = [];
    tabPanes.push({
        collectionName: 'Show All',
        collectionId: 0,
        collectionList: collections
    });

    collections.forEach(collection => {
        tabPanes.push({
            collectionName: collection.collectionName,
            collectionId: collection.collectionId,
            collectionList: [collection]
        });
    });


    return <div className="page-wrapper">
        <div className='header' style={{backgroundImage: `url(${page.pHeaderPictureUrl})`}}>{ReactHtmlParser(page.pageHeaderVerbage)}</div>
        <div className='tabs-wrapper'>
            <Tabs animated={false} >
            {tabPanes.map(({ collectionName, collectionId, collectionList }) => {
            return (
                <Pane tab={collectionName} key={`tab-key-${collectionId}`}>
                    <>{collectionList.map(collection => {
                        return <TabContent {...collection} {...{userId}} />;
                    })}</>
                </Pane>
            );
            })}
            </Tabs>
        </div>
    </div>
}

export default PageContent;