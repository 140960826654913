import { put } from 'redux-saga/effects';
import { getRequest, postRequest } from '_http';
import { USER, CONTENT, TOKEN } from 'actions/actionTypes';

import { includes, split } from 'lodash';
import { action } from 'reduxHelpers';

const adPrefix = process.env.REACT_APP_AD_PREFIX;
const oktaPrefix = process.env.REACT_APP_OKTA_PREFIX;

const getUserId = username => {
  if (includes(username, adPrefix)) {
    return split(username, '@')[0].replace(adPrefix, '');
  }
  var oktaEx = new RegExp(oktaPrefix, 'ig');
  return username.replace(oktaEx, '');
};

function* loadContentAsync({urltoken, userId, emailOrName, captchaToken}) {

  try {

    let data = { user: {}};
    console.log(`userId=`, userId)
    const response = yield getRequest(`/training/content${urltoken ? `/${urltoken}/emailorname/${emailOrName}/captcha/${captchaToken}` : ''}`);
    data.content = response.data;
    
    if (userId && userId.length > 0) {
      try {
        const response2 = yield getRequest(`/user/${getUserId(userId)}?app_name=TRNAPP`);
        data.user = response2.data;
      } catch (e) {}
    }
    yield put({ type: CONTENT.SUCCESS, payload: data});
    if (emailOrName) {
      localStorage.setItem('emailOrName', emailOrName);
    }

  } catch (error) {
    yield put({ type: CONTENT.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'Load Failed: Token/session expired or verification failed.'
      })
    );
  }

}

function* getTokenAsync(data) {
  try {
    const response = yield postRequest(`/training/token`, data);
    yield put({ type: TOKEN.SUCCESS, payload: response.data });

    let description = '';
    if (['email', 'phone'].includes(response.data.sendType)) {
      description = `Sent to ${response.data.sendTo}`;
    } else if (response.data.sendType === 'clipboard') {
      navigator.clipboard.writeText(response.data.fullUrl);
      description = `Copied to Clipboard`;
    }

    yield put(
      action('SHOW_NOTIFICATION', {
        description,
        className: 'success',
        message: 'Successful'
      })
    );
  } catch (error) {
    yield put({ type: TOKEN.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'Couldnt send the token'
      }));
  }
}

function* loadUserAsync({ userId }) {

  try {
    const response = yield getRequest(`/user/${getUserId(userId)}?app_name=TRNAPP`);
    yield put({ type: USER.SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: USER.FAILURE, payload: error.message });
    yield put(
      action('SHOW_NOTIFICATION', {
        description: error.message,
        className: 'error',
        message: 'USER LOADING ERROR'
      })
    );
  }

}

export { loadUserAsync, loadContentAsync, getTokenAsync };
