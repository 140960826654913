import React from 'react';
import { Provider } from 'react-redux';
import store from 'store';
import _ from 'lodash';
import i18n from './_i18n';
import MainApp from './MainApp';

function App(props) {

  let container = _.get(props, 'container', 'external');
  let username = _.get(props, 'Auth.user.username', '');
  let token = _.get(props, 'token', '');

  i18n.changeLanguage('');

  return (
    <Provider store={store}>
      <MainApp container={container} userId={username} token={token} />
    </Provider>
  );
}

export default App;
