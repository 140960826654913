import Content from 'components/Content';
import React, { Component } from 'react';

export class Home extends Component {
  render() {

    let { container, content } =this.props;

    let panelStyles = container === 'external' ? {padding: '0px 25px'} : {};

    return (
      <div style={{width: '100%'}}>
        <div className="grid-filter-panel" style={panelStyles}>
          { container === 'internal' && <div style={{
            display: 'flex',
            alignItems: 'center'
          }}>
            <div
              className="back-to-dashboard"
              onClick={() => {
                window.location.href = '/suite/dashboard';
              }}
            />
            <div className="bread-crum-seperator"></div>
          </div>}
          { container === 'external' && <div style={{
              backgroundImage: 'url("/static/media/sysco-logo.4cfcd7c6.png")',
              width: '100px',
              height: '50px',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain'
          }} />}
          <div className="title">
            { content && content.page ? content.page.pageName : 'Loading...' }
          </div>
        </div>
        <Content {...this.props}/>
      </div>
    );
  }
}

export default Home;
