import initialState from 'store/initialState';

import { USER, CONTENT } from 'actions/actionTypes';

import { createReducer } from 'reduxHelpers';

const user = (state = initialState.user, action) => {
    switch (action.type) {
      case CONTENT.SUCCESS:
        return { ...state, ...action.payload.user, fetching: false };
      default:
        return state;
    }
};

createReducer(USER, initialState.user);

export default user;
