import initialState from 'store/initialState';

import { CONTENT, TOKEN } from 'actions/actionTypes';

import { createReducer } from 'reduxHelpers';

const content = (state = initialState.content, action) => {
    switch (action.type) {
      case CONTENT.REQUEST:
        return { ...state, contentLoading: true };
      case CONTENT.SUCCESS:
        return { ...state, ...action.payload.content, fetching: false, tryLoading: false, retryLoading: false, contentLoading: false  };
      case CONTENT.FAILURE:
        return { ...state, fetching: false, tryLoading: false, retryLoading: true, contentLoading: false };
      case TOKEN.REQUEST:
        return { ...state, sendingToken: true };
      case TOKEN.SUCCESS:
        return { ...state, sendingToken: false };
      case TOKEN.FAILURE:
        return { ...state, sendingToken: false };
      case TOKEN.UPDATE:
        return { ...state, tokenDuration: action.val };
      default:
        return state;
    }
};
createReducer(CONTENT, initialState.content);

export default content;
