import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Home from './pages/Home';
import * as userActions from 'actions';

// TODO: Add the correct app logo
import AppLogo from './styles/images/app-logos/contacts-logo.svg';
import styles from './styles/main.scss';

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = AppLogo;
  }
};

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

function MainApp({ userId, userPermissions, actions, userFetching, container, content }) {
  setAppLogo();
  setAppHeaderToAppMode();

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  /*useEffect(() => {
    actions.loadUserDetails(userId);
  }, [actions, userId]);*/

  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/trainingmaterial/dashboard"
            render={(props) => <Home container={container} userId={userId} {...props} {...content} />}
          />
          <Route
            exact
            path="/suite/trainingmaterial"
            render={(props) => <Home container={container} userId={userId} {...props} {...content} />}
          />
        </Switch>
      </BrowserRouter>
    </React.Fragment>
  );

}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    userPermissions: state.user.data.permissions,
    userFetching: state.user.fetching,
    content: state.content
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainApp);
